import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import AlertBar from './AlertBar'
import Navbar from './Navbar'
import { useUserContext } from 'context'
import { axiosPlain } from 'api'

const Header = ({ controls }) => {
  const { user } = useUserContext()
  const getGa4Events = user && user?.getGa4Events

  useEffect(() => {
    if (getGa4Events) {
      axiosPlain
        .get('/users/events')
        .then((res) => {
          const events = res.data?.ga4_events
          if (events && Array.isArray(events)) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(...events)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [getGa4Events])

  return (
    <React.Fragment>
      {controls.alert && <AlertBar />}
      {controls.navbar && (
        <Navbar links={controls.navbarLinks} auth={controls.navbarAuth} />
      )}
    </React.Fragment>
  )
}

Header.defaultProps = {
  controls: {
    alert: true,
    navbar: true,
    navbarLinks: true, // Links, hoverables, search, etc.
    navbarAuth: true
  }
}

Header.propTypes = {
  controls: PropTypes.object
}

export default Header
